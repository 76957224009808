<template>
    <v-container>
        <v-row dense>
            <v-col cols=6>
                <v-row dense>
                    <v-col cols=12>
                        <v-img width="100%" style="border-radius: 1%;" src='../../assets/aave.png' />
                    </v-col>
                    <v-col cols=12>
                    <v-card class="component1">
                    <v-card-title>
                        <v-row>
                            <v-col class="headline" cols=12>
                                <v-icon class="mx-2 primary--text">mdi-text</v-icon>Description
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row no-gutters>
                            <v-col lg=12>
                                <p class="subtitle-1">What is Aave?</p>
                                <p>
Aave is a decentralized non-custodial money market protocol where users can participate as depositors or borrowers. Depositors provide liquidity to the market to earn a passive income, while borrowers are able to borrow in an overcollateralized (perpetually) or undercollateralized (one-block liquidity) fashion.
</p>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols=4>
                <v-row dense>
                    <v-col cols=12>
                <IntroCard />
                    </v-col>
                    <v-col cols=12>
                        <v-card class="component1">
                            <v-card-title>
                                Invest
                            </v-card-title>
                            <v-card-text>
                                <v-row dense>
                                    <v-col lg=7>
                                        <v-row dense>
                                            <v-col lg=6>
                                                Current Investment
                                            </v-col>
                                            <v-col class="subtitle-1 white--text" lg=6>
                                                $1352.00
                                            </v-col>
                                            <v-col lg=6>
                                                Earned
                                            </v-col>
                                            <v-col class="subtitle-1 white--text" lg=6>
                                                $124.00
                                            </v-col>
                                            <v-col lg=9>
                                               <hr>
                                            </v-col>
                                            <v-col lg=6>
                                                ROI %
                                            </v-col>
                                            <v-col class="subtitle-1 white--text" lg=6>
                                                3.8%
                                            </v-col>
                                        </v-row>
                                    </v-col>

                                </v-row>
                            </v-card-text>
                            <v-card-actions>
                            <v-col  align="center" lg=6>

                            <v-btn @click="overlay = true;" class="primary darken-2">Manage</v-btn>
                                        
                            </v-col>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                    <v-col>
                        <v-card class="component1">
                            <v-card-text>
                            <v-tabs fixed-tabs background-color="component1">
                                <v-tab>
                                    Financials
                                </v-tab>
                                <v-tab>
                                    Documents
                                </v-tab>
                                <v-tab>
                                    Organization
                                </v-tab>
                            </v-tabs>
                            Test
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>

        </v-row>
                       <v-overlay
                :value="overlay"
                opacity="0.6"
                >
                            <VueGlow :color="project.color">
                    <v-card elevation=7 max-width=400px class="component1">
                                                    <v-form>

                        <v-card-title>
                            <v-row no-gutters>
                                <v-col lg=2>
                                </v-col>
                                <v-col align="center" class="headline" lg=8>
                               
                                     Lend on {{project.name}}
                                </v-col>
                                <v-col lg=2 align="right">
                                    <v-btn @click="closeOverlay" icon depressed class="component1 headline"> <v-icon>mdi-close</v-icon></v-btn>
                                </v-col>
                                <v-col class="pt-2" lg=12>
                                    <hr>
                                </v-col>
                            </v-row>
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col align="center" class="pt-12" lg=12>
                                    <v-img src="../../assets/argonAvatar.png" max-width="50px"/>
                                </v-col>
                                <v-col align="center" lg=12>
                                    In order to lend or withdraw enter the amount below
                                </v-col>
                                <v-col align="center" lg=12>
                                    <v-tabs fixed-tabs background-color="component1">
                                        <v-tab @click="setBuy">
                                            Lend
                                        </v-tab>
                                        <v-tab @click="setSell">
                                            Withdraw
                                        </v-tab>
                                    </v-tabs>
                                </v-col>
                            </v-row>
                            <v-row dense align="center">
                                <v-col align="right" lg=12 class="secondary--text">
                                    
                                </v-col>

                                <v-col align="left" lg=12 class="secondary--text">
                                    Current APR {{project.apr}}%
                                </v-col>
                                <v-col lg=12>
                                        <v-text-field
                                        type="number"
                                        outlined
                                        label="Amount"
                                        :hint=" txType=='Deposit' ? 'Amount you will invest' : 'Amount you will withdraw'"
                                        suffix="">
                                            Amount
                                        </v-text-field>
                                </v-col>

                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-row>
                                <v-col align="center" lg=12>
                            <v-btn v-if="txType == 'Deposit'" class="profit black--text" x-large width="90%" elevation=5> Deposit </v-btn>
                            <v-btn v-if="txType == 'Withdraw'" class="error" width="90%" x-large elevation=5> Withdraw</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>

                        </v-form>

                    </v-card>
                            </VueGlow>
                </v-overlay>
    </v-container>
</template>

<script>

import IntroCard from '../../components/ProjectPage/IntroCard'

export default {

    components: {
        IntroCard
    },
    data() {
        return {
            overview: {
                "title" : "Overview",
                "icon" : "mdi-cash",
                "values": [
                    {
                        "name": "Date Launched",
                        "value": "01/02/2017"
                    },
                    {
                        "name": "Activity",
                        "value": "Collateralized Lending"
                    },
                    {
                        "name": "Volatility",
                        "value": "Low"
                    },
                    {
                        "name": "Risk Rating",
                        "value": "A"
                    }
                ]
            },
            overlay: false,
            txType: "Deposit",
            project: {
                name: 'Aave',
                color: 'orange',
                icon: 'DAI',
                apr: '2.3'
            }
        }
    },
    mounted() {
    },

    methods: {

        setBuy() {
            this.txType = 'Deposit'
            this.currentTab = 'Deposit'
        },
        setSell() {
            this.txType = 'Withdraw'
            this.currentTab = 'Withdraw'
        },
        closeOverlay() {
            this.overlay = false
            this.txType = "Deposit"
        }
    }
    
}
</script>


<style scoped>

.imageGradient {
  -webkit-mask-image: linear-gradient(to right, transparent 0%, black 0%, currentColor 50%, transparent 90%);
}

.icon1 {
      transition: 0.5s;
  background: -webkit-linear-gradient( #F7931A  20%, black  130%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

</style>